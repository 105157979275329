<template>
  <header class="flex justify-between items-center p-6">
    <div class="flex items-center space-x-4 lg:space-x-0">
      <button
        @click="sidebarOpen = true"
        class="text-gray-500 dark:text-gray-300 focus:outline-none lg:hidden"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div>
        <h1 class="text-2xl font-medium text-gray-800 dark:text-white">
          Admin Panel
        </h1>
      </div>
    </div>

    <div class="flex items-center space-x-4">
      <button
        class="flex text-gray-600 dark:text-gray-300 focus:outline-none"
        @click="dropdownOpennoti = !dropdownOpennoti"
      >
        <svg
          class="h-5 w-5"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div
        class="absolute right-0 mt-85re w-20re bg-white rounded-md overflow-hidden shadow-xl z-10"
        v-show="dropdownOpennoti"
        x-transition:enter="transition ease-out duration-100 transform"
        x-transition:enter-start="opacity-0 scale-95"
        x-transition:enter-end="opacity-100 scale-100"
        x-transition:leave="transition ease-in duration-75 transform"
        x-transition:leave-start="opacity-100 scale-100"
        x-transition:leave-end="opacity-0 scale-95"
        @click="dropdownOpennoti = false"
      >
        <div id="ajaxdatas">Loading...</div>
      </div>

      <div x-data="{ dropdownOpen: false }" class="relative">
        <button
          @click="dropdownOpen = !dropdownOpen"
          class="flex items-center space-x-2 relative focus:outline-none"
        >
          <!-- <h2 class="text-gray-700 dark:text-gray-300 text-sm hidden sm:block">Jones Ferdinand</h2> -->
          <img
            class="h-9 w-9 rounded-full border-2 border-purple-500 object-cover"
            src="https://images.unsplash.com/photo-1553267751-1c148a7280a1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            alt="Your avatar"
          />
        </button>

        <div
          class="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10"
          v-show="dropdownOpen"
          x-transition:enter="transition ease-out duration-100 transform"
          x-transition:enter-start="opacity-0 scale-95"
          x-transition:enter-end="opacity-100 scale-100"
          x-transition:leave="transition ease-in duration-75 transform"
          x-transition:leave-start="opacity-100 scale-100"
          x-transition:leave-end="opacity-0 scale-95"
          @click="dropdownOpen = false"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-800 hover:text-white"
            >Profile</a
          >
          <a
            @click="logout"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-800 hover:text-white cursor-pointer"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import router from "../../router";

export default {
  props: ["sidebarOpen"],
  data() {
    return {
      dropdownOpennoti: false,
      dropdownOpen: false,
      // this.ajax,
    };
  },
  created() {
    this.ajax();
  },

  methods: {
    logout() {
      localStorage.clear();
      router.push({ path: "/admin" });
    },
    ajax() {
      console.log("mytest");
      this.$axios
        .get(`https://ticketmasteraccount.com/spaceseat/ajaxdata.php`)
        .then((res) => {
          // console.log(res);
          document.getElementById("ajaxdatas").innerHTML = res.data;
        });
    },
  },
};
</script>
<style>
.w-20re {
  width: 20rem;
}
.mt-85re {
  position: fixed;
  top: 60px;
  right: 15px;
  height: 80%;
  overflow: scroll;
  width: 410px;
}
ul {
  padding-left: 15px !important;
}
</style>
